<template>
  <div class="wrapper">
    <div class="content">
      <div class="card">
        <el-page-header
          @back="goBack"
          :content="type === 'add' ? '新增退库单' : '编辑退库单'"
        >
        </el-page-header>
      </div>
    </div>
    <div
      class="card"
      style="margin-top: 20px; display: flex; align-items: center"
    >
      <el-button type="primary" icon="el-icon-plus" @click="handleAddHang"
        >新增行</el-button
      >
      <el-button type="primary" @click="goodVisibel = true">筛选货品</el-button>
      <el-button type="primary" @click="copyVisible = true">粘贴货号</el-button>
      <!-- <el-button type="primary" @click="dialogVisible = true">批量改价</el-button> -->
      <!-- <el-button type="primary" @click="infoVisibel = true">批量改信息</el-button> -->
      <!-- <el-button type="primary" style="margin-left: auto;" @click="tableVisiable = true">列表配置</el-button> -->
    </div>
    <div class="table" style="height: 300px; background: white">
      <u-table
        :max-height="300"
        :row-height="50"
        :data="allot_goods"
        @cell-click="hanldeCellClick"
        :cell-class-name="getCellIndex"
      >
        <!-- <u-table-column type="selection" width="55"> </u-table-column> -->
        <template v-for="(item, index) in rowList">
          <u-table-column
            align="center"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.prop === 'goods_number' ? 280 : ''"
          >
            <template slot-scope="scope">
              <template
                v-if="
                  (item.prop === 'num' &&
                    scope.row.goods_id &&
                    scope.row.goods_number_type === 20) ||
                  (item.prop === 'goods_number' && !scope.row.goods_id)
                "
              >
                <el-input
                  @blur="
                    handleBulr(
                      $event,
                      scope.row.index,
                      scope.column.index,
                      scope.row.goods_number,
                      item.prop
                    )
                  "
                  clearable
                  autofocus="true"
                  :id="'input' + scope.row.index + scope.column.index"
                  v-model="scope.row[item.prop]"
                  :placeholder="
                    item.prop === 'goods_number' ? '请输入条码号' : ''
                  "
                  v-show="
                    coordinate.column === scope.column.index &&
                    coordinate.row === scope.row.index
                  "
                  @keyup.enter.native="
                    handleKeydown(
                      $event,
                      scope.row[item.prop],
                      item.prop,
                      scope.row.index,
                      scope.column.index,
                      scope.row.goods_number
                    )
                  "
                >
                </el-input>
                <span
                  v-show="
                    scope.row.index !== coordinate.row ||
                    scope.column.index !== coordinate.column
                  "
                >
                  {{ scope.row[item.prop] }}
                  <span v-if="!scope.row[item.prop]" style="color: #ccc">{{
                    item.prop === "goods_number"
                      ? "请点击输入条码号，按下Enter键结束"
                      : ""
                  }}</span>
                </span>
              </template>
              <template v-else-if="item.prop === 'num_sum'">
                <el-input
                  v-model="scope.row.num_sum"
                  :disabled="scope.row.goods_number_type === 20 ? false : true"
                  @blur="
                    handleBulr(
                      $event,
                      scope.row.index,
                      scope.column.index,
                      scope.row.goods_number,
                      item.prop,
                      scope.row.number_sum
                    )
                  "
                />
              </template>
              <template v-else-if="item.prop === 'sale_warehouse'">
                <template
                  v-if="
                    scope.row.stockList &&
                    scope.row.stockList.length !== 0 &&
                    scope.row.goods_number_type === 20
                  "
                >
                  <el-select
                    v-model="scope.row.sale_warehouse_id"
                    style="width: 100%; margin-right: 10px"
                    @change="
                      (e) =>
                        handleSelectStock(
                          e,
                          scope.row.index,
                          scope.row.stockList
                        )
                    "
                  >
                    <el-option
                      v-for="item in scope.row.stockList"
                      :key="item.warehouse_id"
                      :value="item.warehouse_id"
                      :label="item.warehouse_name"
                    >
                    </el-option>
                  </el-select>
                </template>
                <template v-else-if="scope.row.goods_number_type === 10">{{
                  scope.row.warehouse_name
                }}</template>
                <template v-else> -- </template>
              </template>
              <template v-else>
                {{
                  item.prop === "goods_number_type"
                    ? scope.row[item.prop] === 10
                      ? "一码一货"
                      : scope.row[item.prop] === 20
                      ? "一码多货"
                      : ""
                    : scope.row[item.prop]
                }}
              </template>
            </template>
          </u-table-column>
        </template>
        <u-table-column
          align="center"
          prop="operation"
          label="操作"
          width="180"
        >
          <template slot-scope="scope">
            <div class="options">
              <el-button
                type="text"
                @click="handleDele(scope.row.index, scope.row)"
                >删除</el-button
              >
            </div>
          </template>
        </u-table-column>
      </u-table>
    </div>
    <div class="table total" style="border-top: 0px">
      <div class="total-left">合计</div>
      <div class="total-right">
        <div class="total-right-item">
          货品数量：<span>{{ total || "0" }}</span>
        </div>
        <div class="total-right-item">
          净金重(g)：<span>{{ weight || "0.00" }}</span>
        </div>
        <div class="total-right-item">
          总重(g)：<span>{{ cancel_total_weight || "0.00" }}</span>
        </div>
        <div class="total-right-item">
          标签价：<span>{{ sale_price || "0.0000" }}</span>
        </div>
        <div class="total-right-item">
          成本价：<span>{{ total_cost_price || "0.0000" }}</span>
        </div>
      </div>
    </div>
    <div class="form" style="margin-bottom: 80px">
      <el-form :model="form" label-width="100px">
        <el-form-item label="选择供应商">
          <el-select
            v-model="form.supplier_id"
            placeholder="请选择供应商"
            filterable
            clearable
          >
            <template v-for="(item, index) in supplierList">
              <el-option
                :label="item.supplier_name"
                :value="item.supplier_id"
                :key="index"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item
          label="创建时间"
          :label-width="formLabelWidth"
          v-if="type === 'sockEdit'"
        >
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.create_time"
            type="datetime"
            style="width: 20%"
            placeholder="选择创建时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="审核时间"
          :label-width="formLabelWidth"
          v-if="type === 'sockEdit'"
        >
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.audit_time"
            type="datetime"
            style="width: 20%"
            placeholder="选择审核日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="form.cancel_remark"
            type="textarea"
            :autosize="{ minRows: 8 }"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <!-- <div style="text-align: right; margin-top: 10px">
      <el-pagination
        background
        layout="total, prev, pager, next, jumper"
        :total="paginTotal"
        :page-size="15"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div> -->
    <div style="margin: 10px auto; text-align: center">
      <el-button
        type="primary"
        style="margin-right: 20px"
        @click="handleSubmit"
        :loading="loading"
        v-if="type === 'add' || type === 'edit'"
        >提交审核</el-button
      >
      <el-button
        type="primary"
        style="margin-right: 20px"
        @click="handleSubmitSock"
        :loading="loading"
        v-if="type === 'sockEdit'"
        >保存并锁定</el-button
      >
      <el-button type="primary" plain @click="handleCancel">取消</el-button>
    </div>
    <el-dialog
      title="粘贴货号"
      :visible.sync="copyVisible"
      width="30%"
      :before-close="handleCopyHide"
    >
      <el-input
        v-if="!copyErrVisible"
        type="textarea"
        v-model="copyString"
        placeholder="请输入货号"
        :autosize="{ minRows: 8, maxRows: 8 }"
      ></el-input>
      <div v-else>
        <div v-for="(item, index) in noCopyArr" :key="index">
          {{ index + 1 }}.{{ item.txt }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="!copyErrVisible">
        <el-button @click="handleCopyHide">取 消</el-button>
        <el-button type="primary" @click="handleCopy" :loading="copyLoading"
          >确 定</el-button
        >
      </span>
      <span slot="footer" class="dialog-footer" v-else>
        <el-button @click="handleCopyHide">取 消</el-button>
        <el-button type="primary" @click="handleCopyContinue"
          >粘贴可操作货号</el-button
        >
      </span>
    </el-dialog>
    <choose-goods
      :goodVisibel="goodVisibel"
      @close="goodVisibel = false"
      @getGoods="handleGoods"
      :formItemList="formItemList"
      :selectGoods="allot_goods"
      v-if="goodVisibel"
      search_value_type="itemValue"
    />
  </div>
</template>

<script>
import storage from "good-storage";
import chooseGoods from "@/components/goods/chooseGoods/Index.vue";
import { getMerchantListReq } from "@/api/authority/menage.js";
import __tag__ from "@/utils/tag.js";
import {
  create,
  details,
  update,
  canceledUpdate,
  handelCanceled,
  getInfo,
} from "@/api/goods/goodsHandler/stock/index.js";
import goodsReq from "@/components/goods/chooseGoods/index.js";
import { getSupplierList } from "@/api/goods/goodsWarehousing/warehousing";
import { getGoodsNumberStockReq } from "@/api/order/offlineOrder/add";
import numeral from "numeral";

export default {
  data() {
    this.tag = __tag__;
    return {
      canCopyArr: [], // 可粘贴的货号
      noCopyArr: [],
      copyErrVisible: false,
      copyLoading: false,
      copyString: "",
      copyVisible: false,
      loading: false,
      form: {},
      total: 0,
      cancel_total_weight: 0, // 总重
      weight: 0, // 总净金重
      total_cost_price: 0, // 总成本价
      sale_price: 0, // 总标签价
      merchantList: [],
      paginTotal: 0,
      formItemList: [
        {
          label: "品类",
          placeholder: "请选择",
          attr: "goods_type_id",
          type: "treeSelect",
        },
        {
          label: "货号",
          placeholder: "请填写货号",
          attr: "goods_number",
          type: "input",
        },
        {
          label: "入库单号",
          placeholder: "请填写入库单号",
          attr: "entry_number",
          type: "input",
        },
        {
          label: "盘点单号",
          placeholder: "请填写盘点单号",
          attr: "check_number",
          type: "input",
        },
        {
          label: "调拨单号",
          placeholder: "请填写调拨单号",
          attr: "allot_number",
          type: "input",
        },
        {
          label: "调仓单号",
          placeholder: "请填写调仓单号",
          attr: "allot_warehouse_number",
          type: "input",
        },
        {
          label: "修改单号",
          placeholder: "请填写修改单号",
          attr: "edit_number",
          type: "input",
        },
        {
          label: "款号",
          placeholder: "请填写入款号",
          attr: "goods_style_number",
          type: "input",
        },
        {
          label: "品名",
          placeholder: "请填写入品名",
          attr: "goods_name",
          type: "input",
        },
        { type: "hierarchy_id", label: "所属门店" },
        { type: "warehouse_id", label: "仓库" },
        { type: "101", label: "款式" },
        { type: "100", label: "材质成色" },
        { type: "103", label: "品牌" },
        { type: "sale_price", label: "标签价" },
        { type: "104", label: "净金重" },
        { type: "entry_time", label: "入库时间" },
      ],
      userInfo: {},
      formInline: {},
      form: {},
      arr: [
        {
          index: "",
          goods_number: "",
          goods_name: "",
          101: "",
          102: "",
          103: "",
          104: "",
          sale_price: "",
          num_sum: "",
          stockList: [],
        },
      ],
      goodVisibel: false,
      tableHeight: 0,
      infoVisibel: false,
      tableVisiable: false,
      dialogVisible: false,
      rowList: [
        {
          prop: "index",
          label: "序号",
        },
        {
          prop: "goods_number",
          label: "货号",
        },
        {
          prop: "goods_name",
          label: "品名",
        },
        {
          prop: "101",
          label: "品类",
        },
        {
          prop: "102",
          label: "产地",
        },
        {
          prop: "103",
          label: "品牌",
        },
        {
          prop: "104",
          label: "净金重",
        },
        {
          prop: "110",
          label: "总重",
        },
        {
          prop: "cost_price",
          label: "成本价",
        },
        {
          prop: "sale_price",
          label: "标签价",
        },
        {
          prop: "sale_warehouse",
          label: "仓库",
        },
        {
          prop: "goods_number_type",
          label: "货品类型",
        },
        {
          prop: "num_sum",
          label: "货品数量",
        },
      ],
      coordinate: { column: -1, row: -1 },
      originData: {},
      supplierList: [],
    };
  },
  components: {
    chooseGoods,
  },
  props: {
    cancel_id: Number,
    type: String,
  },
  // mounted() {
  //   this.setHeight();
  // },
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.getMerchantList();
    this.allot_goods = [...this.arr];
    if (this.type === "edit" || this.type === "sockEdit") {
      this.getDetails();
    } else {
      this.coordinate = { row: 1, column: 1 };
      this.$nextTick(() => {
        try {
          const dom = document.getElementById(
            "input" + this.coordinate.row + this.coordinate.column
          );
          dom.focus();
        } catch (error) {}
      });
    }
    this.handleOriginData();
    this.getSupplierList();
  },
  methods: {
    /*
     *   获取供应商下拉数据
     */
    getSupplierList() {
      getSupplierList().then((res) => {
        if (res.code === 1) {
          this.supplierList = res.data;
        }
      });
    },
    handleCopyContinue() {
      if (!this.canCopyArr.length) {
        this.$message.warning("没有可粘贴货号");
      }
      this.handleGoods(this.canCopyArr);
      this.copyString = "";
      this.canCopyArr = [];
      this.handleCopyHide();
    },
    handleCopyHide() {
      this.copyVisible = false;
      setTimeout(() => {
        this.copyErrVisible = false;
      }, 500);
    },
    // 处理粘贴
    handleCopy() {
      if (this.copyString !== "") {
        this.copyLoading = true;
        let arr = this.copyString.split(/[\r\b\t\n\f]/g);
        const canCopyArr = [];
        const noCopyArr = [];
        arr = arr.filter((item) => {
          if (item) {
            return item;
          }
        });
        arr.forEach((item) => {
          if (item) {
            // 去搜索 需要看结果是否符合条件
            goodsReq({
              goods_number: item,
              allot_state: 1,
              // query_type: "check_goods",
            }).then((res) => {
              if (res.data.list.length) {
                // 是否符合条件
                // 必须是同仓库的
                if (res.data.list[0].usable_count) {
                  canCopyArr.push(res.data.list[0]);
                } else {
                  if (res.data.list[0].goods_tag_id) {
                    const goods_tag_id =
                      res.data.list[0].goods_tag_id.split(",");
                    // 不能选择的标志
                    const tagList = [
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "9",
                      "10",
                      "11",
                      "12",
                    ];
                    goods_tag_id.forEach((tag_id) => {
                      tagList.forEach((tag) => {
                        if (tag_id === tag) {
                          noCopyArr.push({
                            txt: `货号 ${item} 正在${this.tag[tag_id]}，不可粘贴`,
                          });
                        }
                      });
                    });
                  }
                }
              } else {
                noCopyArr.push({
                  txt: `货号 ${item} 不存在`,
                });
              }
              if (canCopyArr.length + noCopyArr.length === arr.length) {
                this.copyLoading = false;
                if (!noCopyArr.length) {
                  this.handleGoods(canCopyArr);
                  this.copyVisible = false;
                  this.copyString = "";
                } else {
                  // 弹框哪些不能复制
                  this.canCopyArr = canCopyArr;
                  this.noCopyArr = noCopyArr;
                  this.copyString = "";
                  this.copyErrVisible = true;
                }
              }
            });
          }
        });
      } else {
        this.$message.error("请输入货号");
      }
    },

    /*
     *  选择的仓库
     */
    handleSelectStock(e, index, list) {
      let num = list.find((item) => item.warehouse_id === e).stock;
      this.allot_goods = this.allot_goods.map((item, i) => {
        if (i === index - 1) {
          item.num_sum = num;
          item.number_sum = num;
          if (
            (this.type === "edit" && item.cancel_id) ||
            (this.type === "sockEdit" && item.cancel_id)
          ) {
            item.cancel_id = null;
          }
        }
        return item;
      });
      this.handleSum();
    },
    handleAddHang() {
      this.allot_goods.push({
        index: "",
        goods_number: "",
        goods_name: "",
        101: "",
        102: "",
        103: "",
        104: "",
        sale_price: "",
        num_sum: "",
        stockList: [],
      });
    },

    // 聚焦
    handleFocus(row, column) {
      const coordinate = { ...this.coordinate };
      coordinate.row = row;
      coordinate.column = column;
      this.coordinate = coordinate;
      this.$nextTick(() => {
        try {
          const dom = document.getElementById(
            "input" + coordinate.row + coordinate.column
          );
          dom.value = "";
          dom.focus();
        } catch (error) {}
      });
    },
    /*
     *  查看详情
     */
    getDetails() {
      const data = {
        cancel_id: this.cancel_id,
        page: 1,
        limit: 10000,
      };
      details(data).then((res) => {
        this.paginTotal = res.data.total;
        this.handleGoods(res.data.list, "edit");
      });
      getInfo({ cancel_id: this.cancel_id }).then((res) => {
        if (res.code === 1) {
          this.form = res.data;
        }
      });
    },
    /*
     *  详情分页操作
     */
    // handleCurrentChange(val) {
    //   const data = {
    //     page: val,
    //     cancel_id: this.cancel_id,
    //   };
    //   details(data).then((res) => {
    //     this.paginTotal = res.data.total;
    //     this.handleGoods(res.data.list);
    //   });
    // },
    handleBulr(e, row, column, goods_number, prop, number_sum) {
      if (prop === "num_sum") {
        if (!e.target.value) {
          this.$message.error("请输入退库数量");
          const coordinate = { ...this.coordinate };
          coordinate.row = row;
          coordinate.column = column;
          this.coordinate = coordinate;
          const dom = document.getElementById(
            "input" + coordinate.row + coordinate.column
          );
          this.$nextTick(() => {
            try {
              dom.focus();
            } catch (error) {}
          });
          return;
        }

        if (Number(e.target.value) > Number(number_sum)) {
          this.$message.error("货品数量不能超过原有的仓库数量");
          const coordinate = { ...this.coordinate };
          coordinate.row = row;
          coordinate.column = column;
          this.coordinate = coordinate;
          const dom = document.getElementById(
            "input" + coordinate.row + coordinate.column
          );
          this.$nextTick(() => {
            try {
              dom.focus();
            } catch (error) {}
          });
          return;
        }
        this.coordinate = {
          column: -1,
          row: -1,
        };
        // 限制不能超过最大值
        this.handleSum();
        return;
      }
      this.coordinate = {
        column: -1,
        row: -1,
      };
    },
    /*
     *  保存并锁定
     */
    handleSubmitSock() {
      const goods_number = [];
      const number = [];
      const arr_cancel_id = [];
      const warehouse_id = [];
      let obj = {};
      this.allot_goods.forEach((item, index) => {
        if (item.goods_id) {
          goods_number[index] = item.goods_number;
          number[index] = item.num_sum;
          arr_cancel_id[index] = item.cancel_id || 0;
          warehouse_id[index] = item.sale_warehouse_id;
        }
      });
      if (!goods_number.length) {
        this.$message.error("请添加货品数据");
        return;
      }
      this.loading = true;
      canceledUpdate({
        arr_cancel_id,
        number,
        goods_number,
        cancel_id: this.cancel_id,
        cancel_remark: this.form.cancel_remark,
        supplier_id: this.form.supplier_id,
        warehouse_id,
        cancel_total_weight: this.cancel_total_weight,
        total_goldweight: this.weight,
        total_sale_price: this.sale_price,
        total_cost_price: this.total_cost_price,
        create_time: this.form.create_time,
        audit_time: this.form.audit_time,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.$emit("hide");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 提交审核
    handleSubmit() {
      const goods_id = [];
      const num = [];
      const warehouse_id = [];
      let obj = {};
      this.allot_goods.forEach((item, index) => {
        if (item.goods_id) {
          goods_id[index] = item.goods_id;
          num[index] = item.num_sum;
          warehouse_id[index] = item.sale_warehouse_id;
        }
      });
      if (!goods_id.length) {
        this.$message.error("请添加货品数据");
        return;
      }
      this.loading = true;
      if (this.type === "add") {
        create({
          goods_id,
          num,
          warehouse_id,
          cancel_remark: this.form.cancel_remark,
          supplier_id: this.form.supplier_id,
          cancel_total_weight: this.cancel_total_weight,
          total_goldweight: this.weight,
          total_sale_price: this.sale_price,
          total_cost_price: this.total_cost_price,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              this.$emit("hide");
              this.$emit("getList");
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        update({
          goods_id,
          num,
          cancel_id: this.cancel_id,
          warehouse_id,
          cancel_remark: this.form.cancel_remark,
          supplier_id: this.form.supplier_id,
          cancel_total_weight: this.cancel_total_weight,
          total_goldweight: this.weight,
          total_sale_price: this.sale_price,
          total_cost_price: this.total_cost_price,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              this.$emit("hide");
              this.$emit("getList");
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    handleDele(index, info) {
      this.allot_goods.forEach((item, _index) => {
        if (_index + 1 === index) {
          this.allot_goods.splice(_index, 1);
        }
      });
      this.handleSum();
    },
    handleSum() {
      let total = 0;
      let weight = 0;
      let cancel_total_weight = 0;
      let sale_price = 0;
      let total_cost_price = 0;
      this.allot_goods.forEach((item) => {
        if (item.num_sum) {
          total += Number(item.num_sum);
        }
        if (item["110"]) {
          cancel_total_weight += Number(item["110"]) * item.num_sum;
        }
        if (item["104"]) {
          weight += Number(item["104"]) * item.num_sum;
        }
        if (item.sale_price !== "") {
          sale_price += Number(item.sale_price) * item.num_sum;
        }
        if (item.cost_price && item.cost_price !== "") {
          total_cost_price += Number(item.cost_price) * item.num_sum;
        }
      });
      this.total = total;
      this.cancel_total_weight = parseFloat(
        parseFloat(cancel_total_weight).toFixed(4)
      );
      this.weight = parseFloat(parseFloat(weight).toFixed(4));
      this.sale_price = parseFloat(parseFloat(sale_price).toFixed(4));
      this.total_cost_price = parseFloat(
        parseFloat(total_cost_price).toFixed(4)
      );
    },
    handleCancel() {
      this.$emit("hide");
    },
    handleOriginData() {
      goodsReq({
        allot_state: 1,
        page: 1,
        limit: 999,
      }).then((res) => {
        if (res.code === 1) {
          const originData = {};
          res.data.list.forEach((item) => {
            originData[item.goods_number] = item.num;
          });
          this.originData = originData;
        }
      });
    },
    handleKeydown(val, value, prop, row, column, goods_number) {
      if (!value && prop !== "num") {
        this.$message.error("请填入货号");
        return;
      }
      if (value > this.originData[goods_number] && prop === "num_sum") {
        this.$message.error("货品数量不能超过原有的仓库数量");
        return;
      }
      // if (value > num) {
      //   this.$message.error("编辑不能超过原有的数量");
      //   return;
      // }
      if (val.keyCode === 13) {
        if (prop === "goods_number") {
          if (!value) {
            return;
          }
          // 请求接口
          goodsReq({ goods_number: value, allot_state: 1 })
            .then((res) => {
              if (res.code === 1) {
                if (res.data.list.length === 0) {
                  this.$message.warning("查询不到该货号");
                }
                const data = {
                  goods_number: value,
                };
                if (this.type === "edit" || this.type === "sockEdit") {
                  data.cancel_id = this.cancel_id;
                  data.isAll = 0;
                }
                getGoodsNumberStockReq(data).then((r) => {
                  if (r.code === 1) {
                    r.data = r.data.map((item) => {
                      return {
                        ...item,
                        warehouse_name: `${item.warehouse_name}(${item.stock}件)`,
                      };
                    });
                    res.data.list[0].stockList = r.data;
                    res.data.list[0].sale_warehouse_id =
                      res.data.list[0].warehouse_id;
                    if (res.data.list[0].goods_number_type === 20) {
                      r.data.forEach((item) => {
                        if (
                          res.data.list[0].warehouse_id === item.warehouse_id
                        ) {
                          res.data.list[0].num_sum = item.stock;
                        }
                      });
                    } else {
                      res.data.list[0].num_sum = res.data.list[0].num;
                    }

                    if (!res.data.total) {
                      this.$message.warning("查询不到该货号");
                      this.allot_goods[row - 1].goods_number = "";
                      const coordinate = { ...this.coordinate };
                      coordinate.row = row;
                      coordinate.column = column;
                      this.coordinate = coordinate;

                      this.$nextTick(() => {
                        try {
                          const dom = document.getElementById(
                            "input" + coordinate.row + coordinate.column
                          );
                          dom.value = "";
                          dom.focus();
                        } catch (error) {}
                      });
                      return;
                    }
                    let breakFlg = false;
                    if (res.data.list[0].goods_tag_id) {
                      const goods_tag_id =
                        res.data.list[0].goods_tag_id.split(",");
                      // 不能选择的标志
                      const tagList = [
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "9",
                        "10",
                        "11",
                        "12",
                      ];
                    }
                    if (breakFlg) {
                      return;
                    }
                    // 数据是否有重复
                    const info = this.allot_goods.find(
                      (item) => item.goods_number === value && item.goods_id
                    );
                    if (info && info.goods_number_type === 10) {
                      const coordinate = { ...this.coordinate };
                      coordinate.row = row;
                      coordinate.column = column;
                      this.coordinate = coordinate;
                      this.$nextTick(() => {
                        try {
                          const dom = document.getElementById(
                            "input" + coordinate.row + coordinate.column
                          );
                          dom.value = "";
                          dom.focus();
                        } catch (error) {}
                      });
                      this.$message.warning("该数据已存在。");
                    } else {
                      this.allot_goods.splice(row - 1, 1, ...res.data.list);
                      // 新增一条空数据
                      this.allot_goods.push({
                        index: "",
                        goods_number: "",
                        goods_name: "",
                        101: "",
                        102: "",
                        103: "",
                        104: "",
                        sale_price: "",
                        num_sum: "",
                        stockList: [],
                      });
                      this.handleSum();
                      const coordinate = { ...this.coordinate };
                      coordinate.row = row + 1;
                      coordinate.column = column;
                      this.coordinate = coordinate;
                      this.$nextTick(() => {
                        try {
                          const dom = document.getElementById(
                            "input" + coordinate.row + coordinate.column
                          );
                          dom.focus();
                        } catch (error) {}
                      });
                    }
                  }
                });
              }
            })
            .catch(() => {
              const coordinate = { ...this.coordinate };
              coordinate.row = row;
              coordinate.column = column;
              this.coordinate = coordinate;
              this.$nextTick(() => {
                try {
                  const dom = document.getElementById(
                    "input" + coordinate.row + coordinate.column
                  );
                  dom.value = "";
                  dom.focus();
                } catch (error) {}
              });
            });
        }
        if (prop === "num_sum") {
          this.handleSum();
        }
        this.coordinate = {
          column: -1,
          row: -1,
        };
      }
    },
    getMerchantList() {
      getMerchantListReq({ not_own: 1 }).then((res) => {
        if (res.code === 1) {
          this.merchantList = res.data;
        }
      });
    },
    async handleGoods(goods, type) {
      this.allot_goods = [...goods, ...this.allot_goods];
      // 去重
      let newArr = [];
      let obj = {};
      for (let i = 0; i < this.allot_goods.length; i++) {
        if (this.allot_goods[i].goods_number) {
          const data = {
            goods_number: this.allot_goods[i].goods_number,
          };
          if (this.type === "edit" || this.type === "sockEdit") {
            data.cancel_id = this.cancel_id;
            data.isAll = 0;
          }
          const res = await getGoodsNumberStockReq(data);
          res.data = res.data.map((item) => {
            return {
              ...item,
              warehouse_name: `${item.warehouse_name}(${item.stock}件)`,
            };
          });
          this.allot_goods[i].stockList = res.data;
          this.allot_goods[i].sale_warehouse_id =
            this.allot_goods[i].warehouse_id;
          if (this.type === "edit" || this.type === "sockEdit") {
            if (type && type === "edit") {
              res.data.forEach((items) => {
                if (
                  this.allot_goods[i].sale_warehouse_id === items.warehouse_id
                ) {
                  this.allot_goods[i].number_sum = items.stock;
                }
              });
            }
          }
          if (!type) {
            res.data.forEach((items) => {
              if (
                this.allot_goods[i].sale_warehouse_id === items.warehouse_id
              ) {
                this.allot_goods[i].num_sum = items.stock;
              }
            });
          } else {
            this.allot_goods[i].num_sum = this.allot_goods[i].stock;
          }
        }

        if (!type) {
          this.allot_goods[i].number_sum = this.allot_goods[i].num_sum;
        }

        const { goods_id } = this.allot_goods[i];
        if (!obj[goods_id]) {
          obj[goods_id] = true;
          const newItem = { ...this.allot_goods[i] };
          newArr.push(newItem);
        }
      }
      this.allot_goods = newArr;
      this.handleSum();
    },
    handleClose() {
      this.dialogVisible = false;
      this.infoVisibel = false;
      this.goodVisibel = false;
    },
    getCellIndex({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex + 1;
      column.index = columnIndex;
    },
    hanldeCellClick(row, column) {
      const coordinate = { ...this.coordinate };
      coordinate.row = row.index;
      coordinate.column = column.index;
      this.coordinate = coordinate;
      this.$nextTick(() => {
        try {
          const dom = document.getElementById(
            "input" + row.index + column.index
          );

          dom.focus();
        } catch (error) {}
      });
    },
    goBack() {
      this.$emit("hide");
    },
    cancelTable() {
      const coordinate = { ...this.coordinate };
      coordinate.row = -1;
      coordinate.column = -1;
      this.coordinate = coordinate;
      this.tableVisiable = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-button--text {
  color: #f56c6c;
}
/deep/ .newly {
  padding: 20px;
  width: 100%;
}
.form {
  background: white;
  padding: 20px 10px;
}
.total {
  display: flex;
  justify-content: space-between;
  line-height: 42px;
  padding: 0 20px;
  background: linear-gradient(0deg, #f3f9ff, #ffffff);
  border-top: 1px solid #ddd;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  &-left {
  }
  &-right {
    display: flex;
    &-item {
      font-size: 14px;
      padding: 0 10px;
      span {
        color: #fd563a;
        font-weight: bold;
      }
    }
  }
}
.btns {
  padding: 20px;
  background: white;
  margin-top: 20px;
  position: fixed;
  left: 84px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 9;
}
.tip {
  margin-bottom: 30px;
  margin-left: 20px;
}
.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.title {
  line-height: 80px;
  font-weight: bold;
  font-size: 16px;
}
</style>
