<template>
  <div>
    <div class="content">
      <div class="card">
        <el-page-header @back="goBack" content="退库单审核"></el-page-header>
      </div>
    </div>
    <div class="table" style="border-top: 1px solid #ddd; margin-top: 10px">
      <el-table :data="tableData" stripe :row-class-name="handleRow">
        <template v-for="(item, index) in rowList">
          <el-table-column
            align="center"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
          >
            <template v-slot="scope">
              <template v-if="item.isImg">
                <el-image
                  :src="scope.row[item.prop]"
                  :preview-src-list="[scope.row[item.prop]]"
                  class="image-slot"
                >
                  <div slot="error" class="image-slot">
                    <img src="../../../../assets/images/no_img.png" />
                  </div>
                </el-image>
              </template>
              <template v-else-if="item.prop === 'goods_name'">
                <div style="display: flex; align-items: center">
                  {{ scope.row.goods_name }}
                  <template v-for="item in scope.row.goods_tag_pic">
                    <img
                      :src="item"
                      :key="item"
                      style="margin-left: 5px; height: 15px"
                    />
                  </template>
                </div>
              </template>
              <template v-else>{{ scope.row[item.prop] }}</template>
            </template></el-table-column
          >
        </template>
      </el-table>
    </div>
    <div class="auditBut">
      <el-button
        type="primary"
        style="margin-right: 20px"
        :loading="auditSuccessVisiable"
        @click="handleAudit(20)"
        >审核通过</el-button
      >
      <el-button type="primary" plain @click="handleAudit(30)"
        >审核不通过</el-button
      >
    </div>
    <el-dialog
      :visible.sync="auditVisiable"
      width="30%"
      title="审核不通过原因"
      :destroy-on-close="true"
      :before-close="cancelAudit"
    >
      <el-form
        ref="auditForm"
        :model="auditForm"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="原因说明" prop="remark">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入审核不通过原因说明"
            v-model="auditForm.remark"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitAudit('auditForm')"
            >提交</el-button
          >
          <el-button @click="cancelAudit">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { details, review } from "@/api/goods/goodsHandler/stock/index";
import no_img from "../../../../assets/images/no_img.png";
export default {
  data() {
    return {
      no_img: no_img,
      rowList: [
        {
          label: "序号",
          prop: "index",
        },
        {
          label: "货品图片",
          prop: "goods_pic",
          isImg: true,
        },
        {
          label: "货号",
          prop: "goods_number",
          width: 220,
        },
        {
          label: "入库单号",
          prop: "entry_number",
          width: 220,
        },
        {
          label: "款号",
          prop: "goods_style_number",
          width: 200,
        },
        {
          label: "品名",
          prop: "goods_name",
          width: 180,
        },
        {
          label: "品类",
          prop: "goods_type_name",
        },
        {
          label: "所属门店",
          prop: "hierarchy_name",
          width: 200,
        },
        {
          label: "仓库",
          prop: "warehouse_name",
        },
        {
          label: "入库时间",
          prop: "create_time",
          width: 200,
        },
        // {
        //   label: "产地",
        //   prop: "105",
        // },
        // {
        //   label: "品牌",
        //   prop: "103",
        // },
        // {
        //   label: "金重(g)",
        //   prop: "104",
        // },
        {
          label: "库存",
          prop: "stock",
        },
        {
          label: "成本价",
          prop: "cost_price",
        },
        {
          label: "标签价",
          prop: "sale_price",
        },
        {
          label: "销售工费",
          prop: "184",
        },
        {
          label: "销售工费类型",
          prop: "185",
          width: 180,
        },
        {
          label: "款式",
          prop: "101",
        },

        {
          label: "材质成色",
          prop: "100",
        },
          {
          label: "总重",
          prop: "110",
        },
        {
          label: "净金重",
          prop: "104",
        },
        {
          label: "主石重",
          prop: "130",
        },
        {
          label: "证书号1",
          prop: "112",
        },
        {
          label: "证书号2",
          prop: "1787",
        },
      ],
      tableData: [],
      auditVisiable: false,
      auditForm: {},
      cancel_status: null,
      auditSuccessVisiable: false,
      rules: {
        remark: [
          {
            required: true,
            message: "请填写审核不通过原因",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    cancel_id: Number,
  },
  created() {
    this.getDetails();
  },
  methods: {
    handleRow({ row, rowIndex }) {
      row.index = rowIndex + 1;
    },
    /*
     *  获取退库单商品详情
     */
    getDetails() {
      details({ cancel_id: this.cancel_id }).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.list;
        }
      });
    },
    /*
     * 返回
     */
    goBack() {
      this.$emit("hide");
    },
    /*
     *  审核
     */
    handleAudit(status) {
      this.cancel_status = status;
      if (status === 20) {
        this.$confirm("确定审核通过吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.auditSuccessVisiable = true;
            this.commitAudit();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else {
        this.auditVisiable = true;
      }
    },
    /*
     *  提交审核失败原因
     */
    submitAudit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.commitAudit();
        }
      });
    },
    cancelAudit() {
      this.auditVisiable = false;
    },
    /*
     *  提交审核
     */
    commitAudit() {
      this.auditSuccessVisiable = false;
      const data = {
        cancel_id: this.cancel_id,
        cancel_status: this.cancel_status,
        remark: this.auditForm.remark,
      };
      review(data)
        .then((res) => {
          if (res.code === 1) {
            this.goBack();
          }
        })
        .catch((res) => {
          this.auditSuccessVisiable = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .newly {
  padding: 20px;
  width: 100%;
}
/deep/.auditBut {
  margin: 20px auto;
  width: 400px;
}
/deep/ .image-slot {
  width: 54px;
  height: 54px;
  margin: 5px 0 0 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>