<template>
  <div>
    <div v-if="!addVisiable && !detailsVisiable && !auditVisiable">
      <div class="card" style="display: flex">
        <div class="left">
          <el-button type="primary" @click="handleAdd" icon="el-icon-plus"
            >新增退库单</el-button
          >
          <!-- <el-button
            type="primary"
            plain
            @click="handleExport"
            icon="el-icon-upload2"
            >导出</el-button
          > -->
        </div>
        <div class="line"></div>
        <div class="middle" style="flex: 1">
          <Cond @onCond="handleCond" function_code="cancel" :mode="mode" />
        </div>
        <div class="right" style="display: flex">
          <TableConf
            function_code="cancel"
            @onRowList="handleRowList"
            :mode="mode"
          />
          <PrintConf function_code="desSockPrint" @onRowList="handlePrintRow" />
          <el-button @click="changeMode" style="margin-left: 10px; height: 40px"
            >切换版式：{{ mode === 1 ? "货品模式" : "单号模式" }}</el-button
          >
        </div>
      </div>
      <div class="total">
        <div class="total-right">
          <div class="total-right-item">
            总数量 <span>{{ info.goods_total }}</span>
          </div>
          <div class="total-right-item">
            总净金重 <span>{{ info.gold_weight }}</span>
          </div>
        </div>
      </div>
      <div
        class="table"
        style="border: 1px solid #ddd; border-radius: 6px; overflow: hidden"
        v-if="rowList.length !== 0"
      >
        <el-table
          :data="tableData"
          stripe
          height="700"
          @row-dblclick="handleDalclick"
        >
          <template v-for="(item, index) in rowList">
            <el-table-column
              v-if="item.field_alias === 'cancel_status_name'"
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="scope.row.cancel_status_name === '审核不通过'"
                    style="color: #fd563a"
                  >
                    {{ scope.row.cancel_status_name }}
                  </div>
                  <div
                    v-else-if="scope.row.cancel_status_name === '审核通过'"
                    style="color: #21bc36"
                  >
                    {{ scope.row.cancel_status_name }}
                  </div>
                  <div v-else>
                    {{ scope.row.cancel_status_name }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="
                item.filed_status && item.field_alias !== 'cancel_status_name'
              "
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
              :filters="item.filed_status"
              :filter-method="filterHandler"
            >
            </el-table-column>
            <el-table-column
              v-else
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <div v-if="item.field_alias === 'goods_pic'">
                  <img
                    :src="scope.row.goods_pic"
                    v-if="scope.row.goods_pic"
                    class="image-slot"
                  />
                  <div slot="error" class="image-slot" v-else>
                    <img src="../../../../assets/images/no_img.png" />
                  </div>
                </div>
                <div v-else>
                  {{ scope.row[item.field_alias] || "--" }}
                </div>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            align="center"
            fixed="right"
            prop="operation"
            label="操作"
            width="180"
          >
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleDetails($event, scope.row)"
                >
                  <el-option label="详情" :value="1"></el-option>
                  <el-option
                    v-if="scope.row.cancel_status === 10"
                    label="审核"
                    :value="2"
                  ></el-option>
                  <el-option
                    v-if="scope.row.cancel_status === 30"
                    label="失败原因"
                    :value="3"
                  ></el-option>
                  <el-option
                    v-if="
                      scope.row.cancel_status === 30 ||
                      noSockIds.includes(scope.row.cancel_id) === true
                    "
                    label="编辑"
                    :value="4"
                  ></el-option>
                  <el-option label="打印" :value="5"></el-option>
                  <el-option
                    label="解锁"
                    v-if="
                      scope.row.cancel_status === 20 &&
                      noSockIds.includes(scope.row.cancel_id) === false
                    "
                    :value="20"
                  ></el-option>

                  <el-option
                    label="删除"
                    :value="22"
                    v-if="
                      scope.row.cancel_status === 30 ||
                      noSockIds.includes(scope.row.cancel_id) === true
                    "
                  ></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          :current-page="cond.page"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="$store.state.pageSizes[0]"
          :page-sizes="$store.state.pageSizes"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <LocalPrint
      :show="false"
      :printRows="printRows"
      :printData="printData"
      :title="title"
      :dialogVisible="localPrintVisible"
      @onClose="localPrintVisible = false"
    />
    <Add
      v-if="addVisiable"
      @hide="getList()"
      @getList="getList"
      :type="type"
      :cancel_id="cancel_id"
    />
    <Details
      v-if="detailsVisiable"
      @hide="getList('back')"
      :cancel_id="cancel_id"
      :showPrint="true"
      :title="title"
    />
    <Audit v-if="auditVisiable" @hide="getList()" :cancel_id="cancel_id" />
    <el-dialog
      :visible.sync="errorVisible"
      width="30%"
      title="审核失败原因"
      :destroy-on-close="true"
      :before-close="cancelError"
    >
      <div>{{ audit_remark }}</div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getList,
  switchCaneList,
  getDesSockPrintReq,
  handelCanceled,
  cancelDel,
} from "@/api/goods/goodsHandler/stock/index";
import { getIsLock } from "@/api/monthSettle/index.js";
import Add from "./Add.vue";
import Details from "./Details.vue";
import Audit from "./Audit.vue";
import Cond from "@/components/cond/Index.vue";
import TableConf from "@/components/tableConf/Index.vue";
import PrintConf from "@/components/printConf/Index.vue";
import LocalPrint from "@/components/localPrint/Index.vue";

export default {
  data() {
    return {
      currRow: {},
      title: "退库单",
      localPrintVisible: false,
      printData: {},
      printRows: [], // 打印的行
      info: {},
      tableData: [],
      total: 0,
      cond: {
        page: 1,
        limit: 50,
      },
      type: "add",
      addVisiable: false,
      detailsVisiable: false,
      auditVisiable: false,
      errorVisible: false,
      cancel_id: 0,
      audit_remark: "",
      rowList: [],
      mode: 1,
      noSockIds: [],
    };
  },
  components: {
    Add,
    Audit,
    Details,
    Cond,
    TableConf,
    PrintConf,
    LocalPrint,
  },
  created() {
    this.getList();
  },
  methods: {
    getDesSockPrint(cond) {
      getDesSockPrintReq({ ...cond })
        .then((res) => {
          if (res.code === 1) {
            this.currRow.select = "";
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            res.data.top = [];
            const data = res.data.data;
            const arr1 = [
              { txt: "创建人", value: data.add_user_name, width: "24%" },
              { txt: "创建时间", value: data.create_time, width: "24%" },
              { txt: "退库单号", value: data.cancel_number, width: "30%" },
            ];
            const arr4 = [
              { txt: "门店", value: data.hierarchy_name, width: "24%" },
            ];
            res.data.bottom = [];
            const arr2 = [{ txt: "备注", value: data.cancel_remark, width: "100%" }];
            const arr3 = [
              { txt: "审核状态", value: data.cancel_status_text, width: "24%" },
              { txt: "审核人", value: data.audit_user_name, width: "24%" },
              {
                txt: "审核时间",
                value: data.audit_time ? data.audit_time : "",
                width: "30%",
              },
            ];
            res.data.top.push(arr1, arr4);
            res.data.bottom.push(arr2, arr3);
            // 计算合计
            this.printData = res.data;
            const sum = {
              index: "合计",
            };
            const decimal = {};
            this.printData.list.forEach((item) => {
              this.printRows.forEach((row) => {
                if (row.is_sum) {
                  if (!sum[row.field_alias]) {
                    sum[row.field_alias] = 0;
                  }
                  if (item[row.field_alias]) {
                    sum[row.field_alias] += Number(item[row.field_alias]);
                    if (!decimal[row.field_alias]) {
                      const arr = String(item[row.field_alias]).split(".");
                      if (arr.length === 2) {
                        decimal[row.field_alias] = arr[1].length;
                      } else {
                        decimal[row.field_alias] = 0;
                      }
                    }
                  }
                }
              });
            });
            // 需要保留多少位小数
            Object.keys(sum).forEach((item) => {
              if (item !== "index") {
                sum[item] = sum[item].toFixed(decimal[item]);
              }
            });
            this.printData.list.push(sum);
            this.localPrintVisible = true;
          }
        })
        .catch(() => {
          this.currRow.select = "";
        });
    },
    handlePrintRow(rows) {
      this.printRows = rows;
    },
    changeMode() {
      this.rowList = [];
      this.mode === 1 ? (this.mode = 2) : (this.mode = 1);
      this.getList();
    },
    handleCond(cond) {
      this.cond = { ...cond, page: 1, limit: this.cond.limit };
      this.getList('search');
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    filterHandler(value, row, column) {
      console.log(value);
      const type = {
        10: "待审核",
        20: "审核通过",
        30: "审核失败",
      };
      const { property } = column;
      return row[property] === type[value];
    },
    /*
     *  获取退库单列表
     */
    getList(type) {
      if (this.$route.params.type && !type) {
        if (this.$route.params.type === "detail") {
          this.detailsVisiable = true;
          this.cancel_id = this.$route.params.key;
        }
      } else {
        this.detailsVisiable = false;
      }
      this.addVisiable = false;
      this.auditVisiable = false;
      this.noSockIds = [];
      getList({ ...this.cond, mode: this.mode }).then((res) => {
        if (res.code === 1) {
          this.info = res.data;
          const status = {
            10: "待审核",
            20: "审核通过",
            30: "审核不通过",
          };
          res.data.list.map((item) => {
            item.cancel_status_text = status[item.cancel_status];
            return item;
          });
          res.data.list.map((item) => {
            for (var key in item) {
              if (!item[key]) {
                item[key] = "--";
              }
            }
            return item;
          });
          // 列表参数
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    handleAdd() {
      this.type = "add";
      this.addVisiable = true;
    },
    handleExport() {
      getList({ ...this.cond, export: 2 }).then((res) => {
        var filename = "退库列表导出.csv";
        res = "\ufeff" + res;
        var blob = new Blob([res], { type: "application/vnd.ms-excel" });
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          var urlCreator = window.URL || window.wibkitURL;
          var url = urlCreator.createObjectURL(blob);
          var a = document.createElement("a");
          a.download = filename;
          a.href = url;
          a.click();
        }
      });
    },
    /*
     *  分页
     */
    handleCurrentChange(val) {
      this.cond.page = val;
      this.getList();
    },
    /*
     *  每页条数
     */
    handleSizeChange(val) {
      this.cond.page_num = val;
      this.getList();
    },
    cancelError() {
      this.errorVisible = false;
      this.getList();
    },
    handleDalclick(row) {
      this.audit_remark = row.remark;
      this.cancel_id = row.cancel_id;
      this.detailsVisiable = true;
    },
    /*
     *  删除
     */
    handelCanceled(cancel_id) {
      this.$confirm("确定要删除该退库单？删除后将无法恢复。", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.noSockIds.includes(cancel_id) === true) {
            handelCanceled({
              type: "all",
              cancel_id: this.cancel_id,
            }).then((res) => {
              this.$message({
                type: "success",
                message: "删除成功！",
              });
              this.getList();
            });
          } else {
            cancelDel({
              cancel_id: this.cancel_id,
            }).then((res) => {
              this.$message({
                type: "success",
                message: "删除成功！",
              });
              this.getList();
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.getList();
        });
    },
    /*
     *  操作
     */
    handleDetails(type, row) {
      this.currRow = row;
      this.audit_remark = row.remark;
      this.cancel_id = row.cancel_id;
      if (type === 1) {
        this.detailsVisiable = true;
      } else if (type === 2) {
        this.auditVisiable = true;
      } else if (type === 3) {
        this.errorVisible = true;
      } else if (type === 4) {
        this.addVisiable = true;
        if (this.noSockIds.includes(row.cancel_id) === true) {
          this.type = "sockEdit";
        } else {
          this.type = "edit";
        }
      } else if (type === 5) {
        this.getDesSockPrint({
          cancel_id: row.cancel_id,
        });
      } else if (type === 22) {
        this.handelCanceled(row.cancel_id);
      } else if (type === 20) {
        getIsLock({
          source: "cancel",
          primary_key_id: row.cancel_id,
        })
          .then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "解锁成功！",
              });
              // this.addVisiable = true;
              this.type = "sockEdit";
              row.select = "";
              this.noSockIds.push(row.cancel_id);
            }
          })
          .catch(() => {
            row.select = "";
          });
      }
    },
  },
};
</script>

<style  lang="less" scoped>
.image-slot {
  width: 54px;
  height: 54px;
  margin: 5px auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }
}
.total {
  display: flex;
  justify-content: space-between;
  line-height: 42px;
  // padding: 0 20px;
  // background: linear-gradient(0deg, #f3f9ff, #ffffff);
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: white;
  margin-bottom: 10px;
  border-radius: 0px 0px 6px 6px;
  &-left {
    // flex-shrink: 0;
  }
  &-right {
    display: flex;
    flex: 1;
    &-item {
      font-size: 15px;
      // padding: 0 10px;
      width: 15%;
      // border-right: 1px solid #ddd;
      text-align: center;
      color: #909399;
      span {
        font-size: 16px;
        color: #e7541e;
      }
    }
  }
}
.middle {
  flex: 1;
}
</style>