<template>
  <div>
    <div class="content">
      <div class="card">
        <el-page-header @back="goBack" content="退库单详情"></el-page-header>
      </div>
    </div>
    <Detail
      @onPrint="handlePrint"
      :showPrint="showPrint"
      :arr="arr"
      type="stockDetails"
      function_code="cancel"
      :rowList1="rowList1"
      :tableData1="tableData1"
      :rowList2="rowList2"
      :tableData2="tableData2"
      :exportUrl="'store/goods.handling.DesStock/exportExcel'"
      :exportData="{
        cancel_id: cancel_id,
      }"
      :exportName="'退库单详情导出文件'"
      @onChangePage1="handlePage"
      @onChangePage2="handlePage2"
      @onSizeChange="handleSizeChange"
      @onSizeChange2="handleSizeChange2"
    />
    <PrintConf
      function_code="desSockPrint"
      @onRowList="handlePrintRow"
      v-show="false"
    />
    <LocalPrint
      :show="false"
      :printRows="printRows"
      :printData="printData"
      :title="title"
      :dialogVisible="localPrintVisible"
      @onClose="localPrintVisible = false"
    />
  </div>
</template>

<script>
import Detail from "@/components/goods/detail/Index.vue";
import {
  details,
  getInfo,
  canLog,
  getDesSockPrintReq,
  detailIndex,
} from "@/api/goods/goodsHandler/stock/index";
import PrintConf from "@/components/printConf/Index.vue";
import LocalPrint from "@/components/localPrint/Index.vue";

export default {
  data() {
    return {
      localPrintVisible: false,
      printData: {},
      printRows: [],
      page: 1,
      page_num: this.$store.state.pageSizes[0],
      arr: [
        {
          id: 0,
          name: "退库单号",
          value: "",
          prop: "cancel_number",
        },
        {
          id: 1,
          name: "供应商名称",
          value: "",
          prop: "supplier_name",
        },
        {
          id: 2,
          name: "货品数量",
          value: "",
          prop: "cancel_total_num",
        },
        {
          id: 3,
          name: "审核状态",
          value: "",
          prop: "cancel_status_text",
        },
        {
          id: 4,
          name: "添加人",
          value: "",
          prop: "add_user_name",
        },
        {
          id: 5,
          name: "创建时间",
          value: "",
          prop: "create_time",
        },
        {
          id: 6,
          name: "审核人员",
          value: "",
          prop: "audit_user_name",
        },
        {
          id: 7,
          name: "审核时间",
          value: "",
          prop: "audit_time",
        },
        {
          id: 8,
          name: "备注",
          value: "",
          prop: "cancel_remark",
        },
        {
          id: 9,
          name: "审核不通过原因",
          value: "",
          prop: "remark",
        },
      ],
      rowList1: [],
      tableData1: {
        list: [],
        total: 0,
      },
      rowList2: [
        {
          label: "操作时间",
          prop: "create_time",
          width: 200,
        },
        {
          label: "操作记录",
          prop: "msg",
        },
        {
          label: "操作人",
          prop: "store_user_realname",
          width: 200,
        },
      ],
      tableData2: {
        list: [],
        total: 0,
      },
      canPage: 1,
      canLimit: this.$store.state.pageSizes[0],
    };
  },
  components: {
    Detail,
    PrintConf,
    LocalPrint,
  },
  props: {
    cancel_id: Number,
    title: String,
    showPrint: Boolean,
  },
  created() {
    this.getDetail();
    this.getGoodsDetails();
    this.getcanLog();
    this.getDetailIndex();
  },
  methods: {
    getDetailIndex() {
      detailIndex({ function_code: "cancel" }).then((res) => {
        if (res.code === 1) {
          res.data.map((item) => {
            item.label = item.field_text;
            item.prop = item.field_alias;
            return item;
          });
          this.rowList1 = res.data.filter((item) => item.t_is_use === 1);
        }
      });
    },
    getDesSockPrint(cond) {
      getDesSockPrintReq({ ...cond }).then((res) => {
        if (res.code === 1) {
          res.data.list.forEach((item, index) => {
            item.index = index + 1;
          });
          res.data.top = [];
          const data = res.data.data;
          const arr1 = [
            { txt: "创建人", value: data.add_user_name, width: "24%" },
            { txt: "创建时间", value: data.create_time, width: "24%" },
            { txt: "退库单号", value: data.cancel_number, width: "30%" },
          ];
          res.data.bottom = [];
          const arr2 = [{ txt: "备注", value: data.remark, width: "100%" }];
          const arr3 = [
            { txt: "审核状态", value: data.cancel_status_text, width: "24%" },
            { txt: "审核人", value: data.audit_user_name, width: "24%" },
            {
              txt: "审核时间",
              value: data.audit_time ? data.audit_time : "",
              width: "30%",
            },
          ];
          res.data.top.push(arr1);
          res.data.bottom.push(arr2, arr3);
          // 计算合计
          this.printData = res.data;
          const sum = {
            index: "合计",
          };
          const decimal = {};
          this.printData.list.forEach((item) => {
            this.printRows.forEach((row) => {
              if (row.is_sum) {
                if (!sum[row.field_alias]) {
                  sum[row.field_alias] = 0;
                }
                if (item[row.field_alias]) {
                  sum[row.field_alias] += Number(item[row.field_alias]);
                  if (!decimal[row.field_alias]) {
                    const arr = String(item[row.field_alias]).split(".");
                    if (arr.length === 2) {
                      decimal[row.field_alias] = arr[1].length;
                    } else {
                      decimal[row.field_alias] = 0;
                    }
                  }
                }
              }
            });
          });
          // 需要保留多少位小数
          Object.keys(sum).forEach((item) => {
            if (item !== "index") {
              sum[item] = sum[item].toFixed(decimal[item]);
            }
          });
          this.printData.list.push(sum);
          this.localPrintVisible = true;
        }
      });
    },
    handlePrint() {
      this.getDesSockPrint({
        cancel_id: this.cancel_id,
      });
    },
    handlePrintRow(rows) {
      this.printRows = rows;
    },
    /*
     *  获取详情
     */
    getDetail() {
      getInfo({ cancel_id: this.cancel_id }).then((res) => {
        if (res.code === 1) {
          this.arr.forEach((item) => {
            item.value = res.data[item.prop] ? res.data[item.prop] : "--";
          });
        }
      });
    },
    /*
     *  获取商品详情
     */
    getGoodsDetails() {
      details({
        cancel_id: this.cancel_id,
        page: this.page,
        limit: this.page_num,
      }).then((res) => {
        if (res.code === 1) {
          this.tableData1 = res.data;
        }
      });
    },
    /*
     *  操作日志
     */
    getcanLog() {
      canLog({
        page: this.canPage,
        cancel_id: this.cancel_id,
        limit: this.canLimit,
      }).then((res) => {
        this.tableData2 = res.data;
      });
    },
    /*
     *   返回
     */
    goBack() {
      this.$emit("hide");
    },
    handlePage(page) {
      this.page = page;
      this.getGoodsDetails();
    },
    handleSizeChange(limit) {
      this.page = 1;
      this.page_num = limit;
      this.getGoodsDetails();
    },
    handleSizeChange2(limit) {
      this.canPage = 1;
      this.canLimit = limit;
      this.getcanLog();
    },
    /*
     *  操作日志分页
     */
    handlePage2(page) {
      this.canPage = page;
      this.getcanLog();
    },
  },
};
</script>

<style lang="less" scoped>
.newly {
  padding: 20px;
  width: 100%;
}
</style>
